import React from 'react';
import { Route } from "react-router-dom";
import PrivacyPolicy from "../modules/system/PrivacyPolicy";
import About from "../modules/system/About";
import Blog from "../modules/system/Blog/Blog";
import SingleBlog from "../modules/system/Blog/SingleBlog";
import SeconderyHeader from '../modules/Header/SeconderyHeader';
import Footer from '../modules/Footer/Footer';
import GuestQuery from '../modules/system/GuestQuery';
import BookingTerms from '../modules/system/BookingTerms';
import WebTerms from '../modules/system/WebTerms';
import FAQs from '../modules/system/Faq/FAQs';

const SystemRoute = ({ match }) => {
    // if (match) {
    //     console.log("match", match)
    // }
    return (
        <div id="filterHeader">
            <SeconderyHeader />
            <div className='container g-profile-container pt-150 font-chzday system mb-5'>
                <Route exact path={match.url} component={PrivacyPolicy} />
                <Route exact path={`${match.url}/privacy-policy`} component={PrivacyPolicy} />
                <Route exact path={`${match.url}/about`} component={About} />
                <Route exact path={`${match.url}/faq`} component={FAQs} />
                <Route exact path={`${match.url}/blog`} component={Blog} />
                <Route exact path={`${match.url}/blog/:slug`} component={SingleBlog} />
                <Route exact path={`${match.url}/guest-query/:queryType`} component={GuestQuery} />
                <Route exact path={`${match.url}/booking-terms`} component={BookingTerms} />
                <Route exact path={`${match.url}/web-terms`} component={WebTerms} />
                <Route exact path={`${match.url}/contact-us`} component={GuestQuery} />
            </div>
            <Footer />
        </div>
    )
}

export default SystemRoute
