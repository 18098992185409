import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react'
import { Animated } from 'react-animated-css';
import T1 from '../../../../dist/images/singleShop/services/arrowdn.png';
import T2 from '../../../../dist/images/singleShop/services/arrowS.png';
import ArrowRight from '../../../../dist/images/singleShop/services/arrowR.png';

const FAQ = ({content}) => {
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    // --------ans show-------------
    const [ansIds, setAnsIds] = useState();
    const [ansVisible, setAnsVisible] = useState(false);
    const [showAns, setShowAns] = useState(false);
    
    //----------------------html to string-------------------
    const extractContent = (text) => {
        const span = document.createElement('span');
        span.innerHTML = text;
        return <p className='shadow-sm bg-light p-2'> {span.textContent} </p>
    };
    const faqIdsForShowSpecific = (id) => {
        setAnsIds(id);
    };

  return (
      <div id="shopServiceItem overflow-hidden">
          {/* {content?.services?.length > 0 && */}
              <div
                  onClick={() => {
                      setVisible(!visible);
                      setTimeout(() => {
                          setShow(!show);
                      }, 500)
                  }}
                  className="category"
              >
                  {show ? <img src={T1} alt="icon" /> : <img src={T2} alt="icon" />}
                  <h5>{content?.name}</h5>
              </div>
          {/* } */}
          {show &&
              <Animated animationIn="fadeIn" isVisible={visible} animationInDelay={200} className='service_content'>
                  {(content?.faqs?.length > 0 &&
                      content?.faqs?.map((faq, index) => (
                          <div className="row mx-0 overflow-hidden" key={faq.id}>
                                {
                                  <div
                                      onClick={() => {
                                          faqIdsForShowSpecific(faq.id);
                                          setAnsVisible(!ansVisible);
                                        //    setTimeout(() => {
                                            setShowAns(!showAns);
                                        //   }, 500)
                                      }}
                                      className="category"
                                  >
                                      {showAns && ansIds == faq.id ? <img src={T1} alt="icon" /> : <img src={T2} alt="icon" />}
                                      <div className="name"> 
                                          <span>{faq?.question}</span>
                                        </div>
                                  </div>
                                }
                              {showAns && ansIds == faq.id &&
                                  <Animated animationIn="fadeIn" isVisible={ansVisible} animationInDelay={200} className='service_content text-wrap '>
                                      <div className=" ps-4 faq_content" 
                                          dangerouslySetInnerHTML={{ __html: faq?.answer }}
                                      />
                                          {/* {extractContent(faq?.answer)} */}
                                      {/* </div> */}
                                  </Animated>
                                }
                            </div>
                      ))
                  )}
              </Animated>
          }
      </div>
  )
}

export default FAQ